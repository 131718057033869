import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Voucher = {
  id: number;
  code: string;
  startDate: string;
  expiryDate: string;
  isValid: boolean;
  valueDescription: string;
  campaignName: string;
  voucherType: string;
  eventAddress: string;
  giveawayAddress: string;
  imageId: string;
  backgroundColor: string;
  unlockVisitThreshold: number;
  recordedVisits: number;
  Program: {
    uuid: string;
    name: string;
    physicalAddress: string;
    brandColor: string;
    imageURL: string;
  };
  isSelected: boolean;
};

// Define a type for the slice state
interface VoucherState {
  voucher: {
    id: number;
    code: string;
    startDate: string;
    expiryDate: string;
    isValid: boolean;
    valueDescription: string;
    campaignName: string;
    voucherType: string;
    eventAddress: string;
    giveawayAddress: string;
    imageId: string;
    backgroundColor: string;
    unlockVisitThreshold: number;
    recordedVisits: number;
    Program: {
      uuid: string;
      name: string;
      physicalAddress: string;
      brandColor: string;
      imageURL: string;
    };
    isSelected: boolean;
    milestoneData?: {
      visitCount: number;
      rewards: Array<{
        bankVoucherCampaignId: number;
        stampingDigitalVoucherIssueThreshold: number;
        claimed: boolean;
      }>;
    };
  } | null;
}

// Define the initial state using that type
const initialState: VoucherState = {
  voucher: null,
};

export const voucherSlice = createSlice({
  name: "voucher",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addVoucher: (state, action: PayloadAction<Voucher>) => {
      // Replace the current program with the new one
      state.voucher = action.payload;
    },
    removeVoucher: (state) => {
      // Clear the current program
      state.voucher = null;
    },
    updateMilestoneData: (state, action) => {
      if (state.voucher) {
        state.voucher.milestoneData = action.payload;
      }
    },
  },
});

export default voucherSlice.reducer;
