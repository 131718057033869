import React, { useCallback, useEffect, useState } from "react";
import "../../shared/imports/_variables.css";
import "./meed.css";
import PWAHeader from "../../Components/pwa-header-meedpage/pwa-header-meed";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import MembershipCard from "../../Components/membership-card/membership-card";
import Place from "../../ImportedComponents/Place/place";
import Null from "../../Components/null/null";
import openNotification from "../../Components/Antd/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import {
  GetMemberships,
  GetMembershipsResponse,
} from "../../services/api/getMemberships";
import { animate, stagger } from "motion";
import { motion } from "framer-motion";
import useGetVerified from "../../hooks/getVerified";

function Meed() {
  const loggedInUser = getLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [voucherCount, setVoucherCount] = useState(0);
  const [memberships, setMemberships] = useState<GetMembershipsResponse>();
  // call handleGetMemberships when the page loads
  const getVerified = useGetVerified();

  useEffect(() => {
    getVerified();
  });

  const handleGetMemberships = useCallback(async () => {
    setIsLoading(true);

    try {
      // console.log(loggedInUser?.uuid ?? "");
      // Store this in redux store
      // Possibly use redux toolkit's query: https://redux-toolkit.js.org/rtk-query/overview
      const response = await GetMemberships(loggedInUser?.uuid ?? "");
      // console.log(response);
      setMemberships(response); // Update the type of 'memberships' state
      // openNotification("success", `Get Membership success`, null);
    } catch (err) {
      console.error(err);
      openNotification("error", `Fail to load memberships`, null);
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    handleGetMemberships();
  }, [handleGetMemberships]);

  useEffect(() => {
    if (memberships) {
      //console.log(memberships);
      const totalVoucherCount = memberships.reduce(
        (sum, membership) => sum + membership.voucherCount,
        0,
      );
      setVoucherCount(totalVoucherCount);
      // console.log(totalVoucherCount);
    }
  }, [memberships]);

  useEffect(() => {
    animate(".meed__card", {
      // Keyframes (array or single value).
      opacity: [0, 1],
    });
  }, []);

  return (
    <div className="pwa-page">
      <PWAHeader />

      <Container className="meed">
        <div className="meed__card">
          <MembershipCard
            voucherCount={voucherCount}
            backgroundColor="#F87C56"
          />
        </div>

        <h2 className="meed__sub-heading">My Memberships</h2>
        <div className="meed__list">
          {isLoading ? (
            <div className="meed__emptiness">Loading...</div>
          ) : (
            <>
              {memberships?.length ? (
                memberships.map((membership, index) => (
                  <motion.div
                    className="meed__item"
                    key={membership.id}
                    initial={{ opacity: 0, y: 300 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.8,
                      delay: index * 0.1,
                      ease: "easeInOut",
                    }}
                  >
                    <Place
                      key={membership.id}
                      id={membership.id}
                      backgroundColor={membership.brandColor}
                      textColor={"#FFFFF"}
                      brandName={membership.name}
                      location={membership.physicalAddress}
                      industry={membership.category}
                      digitalVoucherCount={membership.voucherCount}
                      brandLogo={membership.imageURL}
                      uuid={membership.uuid}
                      description={membership.description}
                      placesDetail={membership.placesDetail}
                      brandPattern={membership.brandPattern}
                    />
                  </motion.div>
                ))
              ) : (
                <div className="meed__emptiness">
                  <Null>
                    Go get some
                    <br /> memberships
                  </Null>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
      <PWANav />
    </div>
  );
}

export default Meed;
