import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

export interface DiscoveryVoucher {
  id: number;
  code: string;
  campaignName: string;
  description: string;
  valueDescription: string;
  startDate: string;
  expiryDate: string;
  imageId: string;
  backgroundImageId: string;
  termsNConditions: string;
}

export interface DiscoveryProgram {
  id: number;
  uuid: string;
  name: string;
  description: string;
  imageURL: string;
  brandColor: string;
  vouchers: DiscoveryVoucher[];
}

export type GetDiscoveryVouchersResponse = DiscoveryProgram[];

export const getDiscoveryVouchers = async (
  shopperUuid: string,
): Promise<GetDiscoveryVouchersResponse> => {
  const response = await backendApi.get<
    any,
    AxiosResponse<GetDiscoveryVouchersResponse>
  >(`v1/voucher/discoveryVouchers/${shopperUuid}`);

  console.log("Discovery Vouchers API Response:", response.data);
  return response.data;
};
