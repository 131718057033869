import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Modal, Button, Collapse } from "antd";
import TabsAlikeNav from "../../Components/tabs-alike-nav-discovery/tabs-alike-nav-discovery";
import VoucherCard from "../../Components/voucher-card/voucher-card";
import PWAHeader from "../../Components/pwa-header-meedpage/pwa-header-meed";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import openNotification from "../../Components/Antd/Notification/Notification";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import getSelectedProgram from "../../hooks/useSelectedProgram";
import useGetVerified from "../../hooks/getVerified";
import {
  getDiscoveryVouchers,
  DiscoveryVoucher,
  DiscoveryProgram,
} from "../../services/api/getDiscoveryVouchers";
import "./discovery.css";
import { GetImage } from "../../services/api/getImage";
import BrandLogoPlaceholder from "../../assets/placeholders/placeHolder.png";
import { ReactComponent as ClockSymbol } from "../../assets/symbols/clock.svg";
import { joinMembership } from "../../services/api/joinMembership";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { programSlice } from "../../store/slices/data";

const { Panel } = Collapse;

interface Voucher {
  id: number;
  code: string;
  campaignName: string;
  description: string;
  valueDescription: string;
  startDate: string;
  expiryDate: string;
  imageId: string;
  backgroundImageId: string;
  termsNConditions: string;
}

interface Program {
  id: number;
  uuid: string;
  name: string;
  description: string;
  imageURL: string;
  brandColor: string;
  vouchers: Voucher[];
}

interface VoucherWithProgram extends Voucher {
  programName: string;
  programId: number;
  programUuid: string;
  brandColor: string;
  programImage: string;
}

function Discovery() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("All");
  const loggedInUser = getLoggedInUser();
  const selectedProgram = getSelectedProgram();
  const [isLoading, setIsLoading] = useState(false);
  const getVerified = useGetVerified();
  const [vouchers, setVouchers] = useState<VoucherWithProgram[]>([]);
  const [selectedVoucher, setSelectedVoucher] =
    useState<VoucherWithProgram | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [brandLogoSrc, setBrandLogoSrc] =
    useState<string>(BrandLogoPlaceholder);
  const dispatch = useDispatch();

  const handleGetVouchers = useCallback(async () => {
    setIsLoading(true);
    try {
      const programs = await getDiscoveryVouchers(loggedInUser?.uuid || "");

      console.log("Discovery API Response:", {
        rawResponse: programs,
        programCount: programs.length,
        voucherDetails: programs.map((program) => ({
          programName: program.name,
          voucherCount: program.vouchers?.length || 0,
          vouchers: program.vouchers,
        })),
      });

      const allVouchers = programs.reduce<VoucherWithProgram[]>(
        (acc, program) => {
          const programVouchers = program.vouchers.map(
            (voucher: DiscoveryVoucher) => ({
              ...voucher,
              programName: program.name,
              programId: program.id,
              programUuid: program.uuid,
              programImage: program.imageURL,
              brandColor: program.brandColor,
            }),
          );
          return [...acc, ...programVouchers];
        },
        [],
      );

      console.log("Processed Vouchers:", allVouchers);

      setVouchers(allVouchers);
    } catch (err) {
      console.error("Discovery API Error:", err);
      openNotification("error", `Failed to load vouchers`, null);
    } finally {
      setIsLoading(false);
    }
  }, [loggedInUser?.uuid]);

  useEffect(() => {
    handleGetVouchers();
  }, [handleGetVouchers]);

  useEffect(() => {
    getVerified();
  }, []);

  const handleVoucherClick = (voucher: VoucherWithProgram) => {
    setSelectedVoucher(voucher);
    setIsModalVisible(true);
  };

  const handleJoinProgram = async (programUuid: string) => {
    try {
      await joinMembership({
        shopperUuid: loggedInUser?.uuid || "",
        membershipUuid: programUuid,
      });

      const selectedProgram = vouchers.find(
        (v) => v.programUuid === programUuid,
      );

      if (selectedProgram) {
        dispatch(
          programSlice.actions.addProgram({
            id: selectedProgram.programId,
            uuid: programUuid,
            name: selectedProgram.programName,
            description: "",
            brandLogo: selectedProgram.programImage,
            brandColor: selectedProgram.brandColor,
            brandPattern: "",
            placesDetail: "",
            isSelected: true,
          }),
        );
      }

      openNotification("success", "Successfully joined program", null);
      setIsModalVisible(false);
      navigate("/home");
    } catch (error) {
      console.error("Join Program Error:", error);
      openNotification("error", "Failed to join program", null);
    }
  };

  const calculateRemainingDays = (expiryDate: string) => {
    const today = new Date().getTime();
    const expiry = new Date(expiryDate).getTime();
    return Math.ceil((expiry - today) / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    if (selectedVoucher?.programImage) {
      GetImage(selectedVoucher.programImage)
        .then((image) => setBrandLogoSrc(image || BrandLogoPlaceholder))
        .catch(() => setBrandLogoSrc(BrandLogoPlaceholder));
    }
  }, [selectedVoucher]);

  return (
    <div className="pwa-page">
      <PWAHeader />
      {/*
      <Container className="discovery">
        <div className="discovery__filters">
          <TabsAlikeNav
            brandColor={selectedProgram?.brandColor || ""}
            onTabSelect={setSelectedTab}
          />
        </div>
      </Container>
      
      */}

      <div className="Discovery-Container-Title">
        <h1>{selectedTab === "All" ? "Featured Vouchers" : selectedTab}</h1>
      </div>

      <Container>
        <AnimatePresence mode="wait">
          <motion.div
            className="vouchers-grid"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {vouchers.map((voucher) => (
              <motion.div
                key={voucher.id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <VoucherCard
                  brandName={voucher.programName}
                  startDate={voucher.startDate}
                  expriyDate={voucher.expiryDate}
                  discount={voucher.valueDescription}
                  product={voucher.campaignName}
                  location=""
                  backgroundColor={voucher.brandColor}
                  voucherImage={voucher.imageId}
                  brandImage={voucher.programImage}
                  textColor="#FFFFFF"
                  unlockVisitThreshold={0}
                  recordedVisits={0}
                  code={voucher.code}
                  programUuid={voucher.programUuid}
                  onClick={() => handleVoucherClick(voucher)}
                />
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>
      </Container>

      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
        className="voucher-detail-modal"
      >
        {selectedVoucher && (
          <div className="voucher-detail">
            <div className="voucher-detail-header">
              <img
                src={brandLogoSrc}
                alt={selectedVoucher.programName}
                className="voucher-detail-brand-logo"
              />
              <div className="voucher-detail-title">
                <h2>{selectedVoucher.programName}</h2>
              </div>
            </div>

            <div className="voucher-detail-content">
              <p className="voucher-detail-campaginName">
                {selectedVoucher.campaignName}
              </p>
              <p className="voucher-detail-value">
                {selectedVoucher.valueDescription}
              </p>
              <p className="voucher-detail-description">
                {selectedVoucher.description}
              </p>

              <div className="voucher-detail-dates">
                <div className="date-item">
                  <ClockSymbol className="timer__icon" />
                  <p>
                    {calculateRemainingDays(selectedVoucher.expiryDate)} days
                    remaining
                  </p>
                </div>
              </div>

              <Collapse ghost className="voucher-detail-collapse">
                <Panel header="Terms & Conditions" key="1">
                  <p>
                    {selectedVoucher.termsNConditions
                      .split(/(?=\d+\.)/)
                      .map((item, index) => (
                        <React.Fragment key={index}>
                          {item.trim()}
                          <br />
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                </Panel>
              </Collapse>
            </div>

            <Button
              type="primary"
              block
              size="large"
              onClick={() => handleJoinProgram(selectedVoucher.programUuid)}
              className="join-button"
            >
              Join Program to Claim
            </Button>
          </div>
        )}
      </Modal>

      <PWANav />
    </div>
  );
}

export default Discovery;
