import React, { useState, useEffect } from "react";
import "./place.css";
import BrandLogoPlaceholder from "../../assets/placeholders/logo-placholder.jpg";
import Location from "../Location/location";
import { useDispatch } from "react-redux";
import { ReactComponent as TicketHorizontalIcon } from "../../assets/symbols/ticket-horizontal.svg";
import { ReactComponent as ChevronRightTiny } from "../../assets/symbols/chevron-right-tiny.svg";
import { GetImage } from "../../services/api/getImage";
import { Link } from "react-router-dom";
import { AppRoute } from "../../shared/const";
import { programSlice } from "../../store/slices/data";

function getTextColor(backgroundColor) {
  // Convert the background color to RGB
  const color =
    backgroundColor.charAt(0) === "#"
      ? backgroundColor.substring(1, 7)
      : backgroundColor;
  const r = parseInt(color.substring(0, 2), 16) / 255;
  const g = parseInt(color.substring(2, 4), 16) / 255;
  const b = parseInt(color.substring(4, 6), 16) / 255;

  // Calculate the luminance of the background color
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return 'black' if the background color is bright, 'white' if it's dark
  return luminance > 0.5 ? "black" : "white";
}
function Place({
  backgroundColor,
  textColor,
  brandLogo,
  brandName,
  location,
  industry,
  digitalVoucherCount,
  uuid,
  description,
  placesDetail,
  brandPattern,
  id,
}) {
  const [imageSrc, setImageSrc] = useState(BrandLogoPlaceholder);
  const [program, setProgram] = useState({
    uuid: "",
    name: "",
    description: "",
    isSelected: false,
  });

  textColor = getTextColor(backgroundColor);

  const dispatch = useDispatch();

  const OnClickMembership = () => {
    const updatedProgram = { ...program, isSelected: true };
    setProgram(updatedProgram);
    dispatch(programSlice.actions.addProgram(updatedProgram));
    // console.log("Membership Clicked");
  };

  useEffect(() => {
    setProgram({
      id: id,
      uuid: uuid,
      name: brandName,
      description: description,
      isSelected: false,
      placesDetail: placesDetail,
      brandLogo: brandLogo,
      brandColor: backgroundColor,
      brandPattern: brandPattern,
    });
    const downloadImage = async () => {
      try {
        const downloadedImage = await GetImage(brandLogo);
        setImageSrc(downloadedImage);
      } catch (error) {
        console.error("Failed to download image", error);
      }
    };

    downloadImage();
  }, [brandLogo]);

  return (
    <Link
      className="place__card"
      onClick={OnClickMembership}
      to={AppRoute.Home}
    >
      <div
        className="place"
        style={{ backgroundColor: backgroundColor, color: textColor }}
      >
        <div className="place__header">
          <div className="place__leaf">
            <img
              className="place__logo"
              src={brandLogo ? imageSrc : BrandLogoPlaceholder}
              alt=""
            />
          </div>
          <div className="place__summary">
            <div className="place__heading">{brandName}</div>
            {/* <div className="place__location">
              <Location>{location}</Location>
            </div> */}
          </div>
          <div className="place__data">
            <TicketHorizontalIcon className="place__voucher" />
            <div className="place__counter">
              {digitalVoucherCount ? digitalVoucherCount : 0}
            </div>
          </div>
        </div>
        <div className="place__footer">
          <div className="place__category">{industry}</div>
          <div className="place__more" href="#">
            <ChevronRightTiny className="place__chevron" />
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Place;
