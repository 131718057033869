import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppRoute } from "../../shared/const";
import "../../shared/imports/hardcoded-components/deal.css";
import BrandLogoPlaceholder from "../../assets/placeholders/placeHolder.png";
import "./voucher-card.css";

import { GetImage } from "../../services/api/getImage";
import Location from "../../ImportedComponents/Location/location";
import { ReactComponent as ClockSymbol } from "../../assets/symbols/clock.svg";
import { voucherSlice } from "../../store/slices/voucherData";
import { useDispatch } from "react-redux";
import { ReactComponent as GiftCard } from "../../assets/symbols/giftCard.svg";
import { ReactComponent as Check } from "../../assets/symbols/Check.svg";

interface MilestoneReward {
  bankVoucherCampaignId: number;
  stampingDigitalVoucherIssueThreshold: number;
  claimed?: boolean;
}

type Props = {
  brandName: string;
  startDate: string;
  expriyDate: string;
  discount: string;
  product: string;
  location: string;
  backgroundColor: string;
  voucherImage: string;
  brandImage: string;
  textColor: string;
  unlockVisitThreshold: number;
  recordedVisits: number;
  code: string;
  programUuid: string;
  milestoneRewards?: MilestoneReward[];
  visitCount?: number;
  onClick?: () => void;
};

function calculateDateDifference(expiryDate: string | number | Date) {
  // Create date objects for today and the expiry date
  const today = new Date().getTime();
  const expiry = new Date(expiryDate).getTime();

  // Calculate the difference in milliseconds
  const diffInMs = expiry - today;

  // Convert the difference from milliseconds to days
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;
}

const VoucherCard: React.FC<Props> = ({
  brandName,
  startDate,
  expriyDate,
  discount,
  product,
  location,
  backgroundColor,
  voucherImage,
  textColor,
  brandImage,
  unlockVisitThreshold,
  recordedVisits,
  code,
  milestoneRewards,
  visitCount,
  onClick,
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(BrandLogoPlaceholder);
  const [logoSrc, setLogoSrc] = useState<string | null>(BrandLogoPlaceholder);
  const [voucher, setVoucher] = useState({
    id: 0,
    code: "",
    startDate: "",
    expiryDate: "",
    isValid: false,
    valueDescription: "",
    campaignName: "",
    voucherType: "",
    eventAddress: "",
    giveawayAddress: "",
    imageId: "",
    backgroundColor: "",
    unlockVisitThreshold: 0,
    recordedVisits: 0,
    Program: {
      uuid: "",
      name: "",
      physicalAddress: "",
      brandColor: "",
      imageURL: "",
    },
    isSelected: false,
  });
  const dispatch = useDispatch();

  const progressBarWidth =
    ((recordedVisits ?? 0) / (unlockVisitThreshold ?? 1)) * 100 + "%";

  const OnClickVoucher = () => {
    if (onClick) {
      onClick();
    } else {
      const updatedVoucher = { ...voucher, isSelected: true };
      setVoucher(updatedVoucher);
      dispatch(voucherSlice.actions.addVoucher(updatedVoucher));
    }
  };
  useEffect(() => {
    setVoucher({
      id: 0,
      code: code,
      startDate: startDate ?? "",
      expiryDate: expriyDate ?? "",
      isValid: false,
      valueDescription: discount,
      campaignName: product,
      voucherType: "",
      eventAddress: "",
      giveawayAddress: "",
      imageId: voucherImage,
      backgroundColor: backgroundColor,
      unlockVisitThreshold: unlockVisitThreshold,
      recordedVisits: recordedVisits,
      Program: {
        uuid: "",
        name: brandName,
        physicalAddress: location,
        brandColor: backgroundColor,
        imageURL: brandImage,
      },
      isSelected: false,
    });
    const downloadImage = async (url: string) => {
      try {
        return await GetImage(url);
      } catch (error) {
        console.error("Failed to download image", error);
        return null;
      }
    };

    Promise.all([downloadImage(voucherImage), downloadImage(brandImage)]).then(
      ([downloadedVoucherImage, downloadedBrandImage]) => {
        setImageSrc(downloadedVoucherImage);
        setLogoSrc(downloadedBrandImage);
      },
    );
  }, [voucherImage, brandImage]);
  // console.log(voucherImage);
  startDate = calculateDateDifference(expriyDate).toString();
  voucherImage = voucherImage || "";
  return (
    <div className="home__item">
      <div className="deal">
        <Link
          className="deal__card"
          to={
            onClick && milestoneRewards == undefined ? "#" : AppRoute.HomeItem
          }
          onClick={(e) => {
            if (onClick && milestoneRewards == undefined) {
              e.preventDefault();
            }
            OnClickVoucher();
          }}
        >
          <div
            className="deal__stripe"
            style={{
              background: 935523,
              backgroundColor: backgroundColor,
              color: textColor,
            }}
          ></div>
          <div className="deal__head">
            <div className="deal__venue">{brandName}</div>
            {Number(startDate) < 10000 && (
              <div className="deal__countdown">
                <div className="timer">
                  <ClockSymbol className="timer__icon" />
                  {startDate} days
                </div>
              </div>
            )}
          </div>
          <div className="deal__body">
            <div className="deal__preview">
              <img
                className="deal__illustration"
                src={voucherImage ? imageSrc || "" : BrandLogoPlaceholder}
              />

              <div className="deal__leaf">
                <img
                  className="deal__logo"
                  src={brandImage ? logoSrc || "" : BrandLogoPlaceholder}
                  alt=""
                />
              </div>
            </div>
            <div className="deal__content">
              <div className="deal__offer-heading">{product}</div>
              <div className="deal__offer-value">{discount}</div>
              {/* <div className="deal__location">
                <div className="location">
                  <Location variant={undefined}>{location}</Location>
                </div>
              </div> */}
            </div>
          </div>
          {unlockVisitThreshold != 0 &&
            recordedVisits < unlockVisitThreshold && (
              <div className="deal__vocher">
                <div className="deal__progress-inner">
                  <div className="deal__visual-progress">
                    <div className="progress">
                      <div
                        className="progress__value"
                        style={{
                          width: progressBarWidth,
                          backgroundColor: "#4B76DB",
                        }}
                      ></div>
                      {milestoneRewards?.map((reward, index) => (
                        <div
                          key={index}
                          className={`milestone-reward ${reward.claimed ? "claimed" : ""}`}
                          style={{
                            left: `${(reward.stampingDigitalVoucherIssueThreshold / unlockVisitThreshold) * 100}%`,
                          }}
                        >
                          {reward.claimed ? <Check /> : <GiftCard />}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
        </Link>
      </div>
    </div>
  );
};

export default VoucherCard;
