import React from "react";

function IframeTest() {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Iframe Test Page</h1>
      <iframe
        src="/meed-offers"
        width="100%"
        height="800px"
        frameBorder="0"
        style={{ border: "none" }}
      />
    </div>
  );
}

export default IframeTest;
