import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../shared/imports/_variables.css";
import "./auth.css";
import { ReactComponent as ChevronLeftSymbol } from "../../assets/symbols/chevron-left.svg";
import authOtpRegisterImagePath from "./content/auth-otp-register@3x.jpg";
import AppButton from "../../ImportedComponents/AppButton/app_button";
import Container from "../../Components/container/container";
import SplitInput from "../../ImportedComponents/SplitInput/split_input";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import openNotification from "../../Components/Antd/Notification/Notification";
import { Emaillogin, Phonelogin } from "../../services/api/login";
import { getDeviceUuid } from "../../utils/getDeviceUuid";
import { getEmailOtp, getPhoneOtp } from "../../services/api/getOtp";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { authSlice } from "../../store/slices/auth";
import axios from "axios";
import backendApi from "../../services/api/backendApi";
import { programSlice } from "../../store/slices/data";
import { joinMembership } from "../../services/api/joinMembership";
import {
  GetMembershipsResponse,
  GetMemberships,
} from "../../services/api/getMemberships";
import { updateName } from "../../services/api/updateName";
import useViewportMeta from "../../hooks/useViewportMeta";

const getMembership = async (loggedInUserUuid: string, programUuid: string) => {
  const memberships = await GetMemberships(loggedInUserUuid);

  return memberships.find((x) => x.uuid === programUuid);
};

function Verification() {
  const phoneNumber = useSelector((state: RootState) => state.auth.phoneNumber);
  const email = useSelector((state: RootState) => state.auth.emailForLogin);

  // const isLoginTypeNumber = useSelector(
  //   (state: RootState) => state.auth.isLoginTypeNumber,
  // );
  useViewportMeta();
  const isLoginTypeNumber = true;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ver_code, setver_code] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const turnstileRef = useRef<TurnstileInstance>(null);
  const [requireTurnstile, setRequireTurnstile] = useState(false);
  const { search } = useLocation();
  const [program, setProgram] = useState({
    id: 0,
    uuid: "1",
    name: "2",
    description: "3",
    isSelected: false,
    brandLogo: "4",
    brandColor: "5",
    placesDetail: "6",
    brandPattern: "7",
  });
  const [membership, setMembership] = useState<GetMembershipsResponse[0]>();

  const redirect = useMemo(
    () => new URLSearchParams(search).get("redirect") || undefined,
    [search],
  );

  useEffect(() => {
    // If email is not set, redirect back to login page
    console.log("membership uuid: " + localStorage.getItem("MembershipUuid"));
    if (isLoginTypeNumber && !phoneNumber) {
      navigate("/");
    }

    if (!isLoginTypeNumber && !email) {
      navigate("/");
    }
  }, [navigate, phoneNumber, email]);

  const handlePhoneLogin = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();

      if (!phoneNumber) {
        throw new Error("Email not set");
      }
      if (!ver_code) {
        openNotification("warning", "Please enter a OTP.", null);
        return;
      }

      setIsLoading(true);
      console.log(phoneNumber);
      console.log(ver_code);
      try {
        const response = await Phonelogin({
          phoneNumber: phoneNumber,
          otpCode: ver_code,
          deviceID: getDeviceUuid(),
        });

        // const response = await backendApi.post(`${process.env.REACT_APP_API_URL}v3/auth/shopper/pwa/login`, {
        //   phoneNumber: "+919852339220",
        //   otpCode: ver_code,
        //   deviceID: getDeviceUuid()
        // });

        console.log(response);

        dispatch(
          authSlice.actions.login({
            phoneNumber: response.shopper.email,
            id: response.shopper.id,
            name: response.shopper.name,
            uuid: response.shopper.uuid,
            email: response.shopper.encryptedEmail,
          }),
        );

        // openNotification("success", `User verified successfully`, null);
        if (localStorage.getItem("userName")) {
          const displayName = localStorage.getItem("userName") || "";
          try {
            const updateNameresponse = await updateName({
              shopperUuid: response.shopper.uuid,
              name: displayName,
            });

            console.log(updateNameresponse);
            //openNotification("success", `Your name updated successfully`, null);
          } catch (err) {
            console.log(err);
          }
        }
        // if (!response.shopper.encryptedEmail) {
        //   navigate(`/emailOpt`);
        // }
        const membershipUuid = localStorage.getItem("MembershipUuid");
        if (membershipUuid) {
          getMembership(response.shopper.uuid, membershipUuid)
            .then(async (membership) => {
              if (membership) {
                setMembership(membership);
                const newProgram = {
                  id: membership?.id || 0,
                  uuid: membership?.uuid || "",
                  name: membership?.name || "",
                  description: membership?.description || "",
                  isSelected: true,
                  brandLogo: membership?.imageURL || "",
                  brandColor: membership?.brandColor || "",
                  placesDetail: membership?.placesDetail || "",
                  brandPattern: membership?.brandPattern || "",
                };
                setProgram(newProgram);
                dispatch(programSlice.actions.addProgram(newProgram));
                localStorage.setItem("lastLogin", new Date().toISOString());
                if (membershipUuid) {
                  localStorage.removeItem("MembershipUuid");
                }

                console.log(
                  `encryptedEmail: ${response.shopper.encryptedEmail}`,
                );
                // if (response.shopper.encryptedEmail) {
                navigate(redirect || "/home");
                // } else {
                //   navigate(`/emailOpt`);
                // }
                setIsLoading(false);
                return;
              }

              try {
                const referrer = localStorage.getItem("referrer");

                console.log(referrer);
                const joinPayload: {
                  membershipUuid: string;
                  shopperUuid: string;
                  referrerShopperUuid?: string;
                  locationId?: number;
                } = {
                  membershipUuid: membershipUuid,
                  shopperUuid: response.shopper.uuid,
                  referrerShopperUuid: referrer
                    ? localStorage.getItem("referrer") || undefined
                    : undefined,
                };

                if (!referrer) {
                  joinPayload.locationId = localStorage.getItem("locationID")
                    ? Number(localStorage.getItem("locationID"))
                    : undefined;
                }

                await joinMembership(joinPayload);
                setIsLoading(false);
                localStorage.setItem("newMembership", "true");
                const newMembership = await getMembership(
                  response.shopper.uuid,
                  membershipUuid,
                );
                setMembership(newMembership);
                setProgram({
                  id: newMembership?.id || 0,
                  uuid: newMembership?.uuid || "",
                  name: newMembership?.name || "",
                  description: newMembership?.description || "",
                  isSelected: true,
                  brandLogo: newMembership?.imageURL || "",
                  brandColor: newMembership?.brandColor || "",
                  placesDetail: newMembership?.placesDetail || "",
                  brandPattern: newMembership?.brandPattern || "",
                });
                if (membershipUuid) {
                  localStorage.removeItem("MembershipUuid");
                  localStorage.removeItem("referrer");
                }
              } catch (e) {
                openNotification(
                  "error",
                  `Failed to join membership for the program`,
                  null,
                );
                if (membershipUuid) {
                  localStorage.setItem("MembershipUuid", membershipUuid);
                  localStorage.setItem("ScanMembershipUuid", membershipUuid);
                }
                navigate(`/`);
              }
              setIsLoading(false);
            })
            .catch(() => {
              openNotification("error", `Failed to load memberships`, null);
              dispatch(authSlice.actions.logout());
              localStorage.clear();
              if (membershipUuid) {
                localStorage.setItem("MembershipUuid", membershipUuid);
                localStorage.setItem("ScanMembershipUuid", membershipUuid);
              }
              navigate(`/`);
            });
        } else {
          console.log(`encryptedEmail: ${response.shopper.encryptedEmail}`);
          // if (response.shopper.encryptedEmail) {
          localStorage.removeItem("phoneNumber");
          navigate(redirect || "/meed");
          // } else {
          //   navigate(`/emailOpt`);
          // }
        }
      } catch (err) {
        console.error(err);
        openNotification("error", `Invalid OTP`, null);
      } finally {
        setIsLoading(false);
      }
    },
    [ver_code, navigate, redirect, search, phoneNumber, dispatch],
  );
  useEffect(() => {
    if (program.uuid !== "1") {
      dispatch(programSlice.actions.addProgram(program));
      localStorage.setItem("lastLogin", new Date().toISOString());
      localStorage.removeItem("MembershipUuid");
      navigate(redirect || "/home");
    }
  }, [program, navigate]);
  const handleEmailLogin = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      console.log("using email login");
      return;
      // e.preventDefault();

      // if (!email) {
      //   throw new Error("Email not set");
      // }
      // if (!ver_code) {
      //   openNotification("warning", "Please enter a OTP.", null);
      //   return;
      // }

      // setIsLoading(true);

      // try {
      //   const response = await Emaillogin({
      //     email: email,
      //     otpCode: ver_code,
      //     deviceID: getDeviceUuid(),
      //   });
      //   // const response = await backendApi.post(`${process.env.REACT_APP_API_URL}v3/auth/shopper/pwa/login`, {
      //   //   phoneNumber: "+919852339220",
      //   //   otpCode: ver_code,
      //   //   deviceID: getDeviceUuid()
      //   // });

      //   console.log(response);

      //   dispatch(
      //     authSlice.actions.login({
      //       phoneNumber: "",
      //       id: response.shopper.id,
      //       name: response.shopper.name,
      //       uuid: response.shopper.uuid,
      //       email: response.shopper.email,
      //     }),
      //   );

      //   openNotification("success", `User verified successfully`, null);
      //   localStorage.setItem("lastLogin", new Date().toISOString());
      //   if (response.shopper.name) {
      //     navigate(redirect || "/meed");
      //   } else {
      //     // If name is not set, redirect to name page to ask user to enter their display name
      //     navigate({
      //       pathname: "/name",
      //       search,
      //     });
      //   }
      // } catch (err) {
      //   console.error(err);
      //   openNotification("error", `Invalid OTP`, null);
      //   dispatch(authSlice.actions.logout());
      //   navigate("/");
      // } finally {
      //   setIsLoading(false);
      // }
    },
    [ver_code, navigate, redirect, search, phoneNumber, dispatch],
  );

  const handleSendOTP = useCallback(
    async (turnstileToken: string) => {
      if (isLoginTypeNumber && !phoneNumber) {
        throw new Error("Phone Number not set");
      }
      if (!isLoginTypeNumber && !email) {
        throw new Error("Email not set");
      }

      if (!turnstileToken) {
        openNotification(
          "warning",
          "Please complete the captcha challenge.",
          null,
        );
        return;
      }

      setIsLoading(true);
      try {
        if (isLoginTypeNumber && phoneNumber) {
          await getPhoneOtp({
            phoneNumber,
            deviceID: getDeviceUuid(),
            // turnstileToken,
          });
          openNotification(
            "success",
            `An OTP has been delivered to the phone number ${phoneNumber}`,
            null,
          );
        } else if (email) {
          await getEmailOtp({
            email,
            deviceID: getDeviceUuid(),
            // turnstileToken,
          });
          openNotification(
            "success",
            `An OTP has been delivered to the email address ${email}`,
            null,
          );
        } else {
          throw new Error("Phone number or email is undefined.");
        }
        setRequireTurnstile(false);
      } catch (err) {
        console.error(err);
        openNotification("error", `Unable to send OTP`, null);
      } finally {
        setIsLoading(false);
      }
      setCanResendOTP(false);
      setTimer(60);
    },
    [phoneNumber, navigate, turnstileRef.current],
  );

  const [timer, setTimer] = useState(60);
  const [canResendOTP, setCanResendOTP] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer == 0) {
      setCanResendOTP(true);
    }
  }, [timer]);

  return (
    <div style={{ background: "white" }}>
      <div className="auth">
        <div className="auth__head">
          <Container className="auth__head-wrap" variant="smartphone-column">
            <a className="auth__back" href="#">
              <ChevronLeftSymbol
                className="auth__chevron"
                onClick={() => {
                  navigate("/");
                }}
              />
              <span className="visuallyhidden">Назад</span>
            </a>
          </Container>
        </div>
        <form
          onSubmit={isLoginTypeNumber ? handlePhoneLogin : handleEmailLogin}
        >
          <fieldset
            disabled={isLoading}
            className="auth__fieldset"
            style={{ border: "none" }}
          >
            <Container className="auth__wrap" variant="smartphone-column">
              <img
                className="auth__illustration"
                src={authOtpRegisterImagePath}
                width="260"
                height="243"
                alt=""
              />
              <div className="auth__heading">Enter the Verification Code</div>
              <div className="auth__note">
                Enter the 4-digit number that we sent to {phoneNumber}
              </div>
              <div className="auth__panel">
                <SplitInput>
                  <OtpInput
                    value={ver_code}
                    onChange={setver_code}
                    numInputs={4}
                    inputType={"tel"}
                    shouldAutoFocus={true}
                    skipDefaultStyles={true}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="input"
                  />
                </SplitInput>
                <div className="auth__code-hint">
                  The code is valid for an hour.
                </div>
              </div>
              <div className="auth__submit">
                <AppButton uniqueModifier="submit" fullWidth type="submit">
                  Log in
                </AppButton>
              </div>
              <div className="auth__alternation">
                Didn’t Receive Anything?{" "}
                <button
                  className="auth__alt-action"
                  type="button"
                  disabled={!canResendOTP}
                  style={{
                    color: `${!canResendOTP ? "gray" : ""}`,
                    cursor: `${!canResendOTP ? "not-allowed" : "pointer"}`,
                  }}
                  onClick={() => {
                    if (requireTurnstile) {
                      turnstileRef.current?.reset();
                    } else {
                      setRequireTurnstile(true);
                    }
                  }}
                >
                  Resend&nbsp;Code&nbsp;
                </button>
                {!canResendOTP && `${timer}s`}
                {requireTurnstile && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Turnstile
                      siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY ?? ""}
                      options={{
                        appearance: "interaction-only",
                      }}
                      onError={() => console.log("captcha validation failed")}
                      onSuccess={handleSendOTP}
                      ref={turnstileRef}
                    />
                  </div>
                )}
              </div>
            </Container>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default Verification;
