import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import "../../shared/imports/_variables.css";
import "./auth.css";
import LogoImagePath from "./../../assets/icons/meed_full_logo.png";
import AppInput from "../../ImportedComponents/AppInput/app_input";
import AppButton from "../../ImportedComponents/AppButton/app_button";
import Label from "../../ImportedComponents/Label/label";
import Container from "../../Components/container/container";
import { getDeviceUuid } from "../../utils/getDeviceUuid";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { useLocation, useNavigate } from "react-router-dom";
import { getPhoneOtp } from "../../services/api/getOtp";
import Capcha from "../../Components/capcha/capcha";
import openNotification from "../../Components/Antd/Notification/Notification";
import { authSlice } from "../../store/slices/auth";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import "../../shared/plugins-overrides/react-international-phone.css";
import { useDispatch } from "react-redux";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import {
  GetMemberships,
  GetMembershipsResponse,
} from "../../services/api/getMemberships";
import ReactGA from "react-ga4";
import LoadingSpinner from "../../Components/loading/loading";
import { programSlice } from "../../store/slices/data";
import { joinMembership } from "../../services/api/joinMembership";
import { logout } from "../../services/api/logout";
import { datadogRum } from "@datadog/browser-rum";
import {
  GetProgramLogo,
  GetProgramInfo,
  ProgramInfo,
} from "../../services/api/getProgramLogo";
import { useParams } from "react-router-dom";
import { CheckPhone } from "../../services/api/checkPhone";
import Deal from "../../Components/deal/deal";
import useViewportMeta from "../../hooks/useViewportMeta";
import { GetVouchersByLocation } from "../../services/api/getVouchers";

ReactGA.initialize("G-HGGV9ZH1CS");
datadogRum.init({
  applicationId: "0ce1e31e-096f-4831-9eab-6daf5e87e017",
  clientToken: "pub476cdf0193697d98f69dc2fe95ca9f42",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us5.datadoghq.com",
  service: "meed",
  env: "dev-meed",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 24,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
}

const getMembership = async (loggedInUserUuid: string, programUuid: string) => {
  const memberships = await GetMemberships(loggedInUserUuid);
  return memberships.find((x) => x.uuid === programUuid);
};

const getOfferVoucherUuid = (redirect: string | null): string | null => {
  if (!redirect?.startsWith("/membership/")) {
    return null;
  }

  const pathParts = redirect.split("/").filter(Boolean);
  if (pathParts.length < 3) {
    return null;
  }

  const offerVoucherPart = pathParts[2];
  if (offerVoucherPart.startsWith("offerVoucherUuid=")) {
    return offerVoucherPart.replace("offerVoucherUuid=", "");
  }

  return offerVoucherPart;
};

function WellcomeBusiness() {
  usePageViews();
  useViewportMeta();
  const loggedInUser = useLoggedInUser();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [membership, setMembership] = useState<GetMembershipsResponse[0]>();
  const hasRedirected = useRef<boolean>(false);
  const isLoginTypeNumber = true;
  const { search } = useLocation();
  const navigate = useNavigate();
  const turnstileRef = useRef<TurnstileInstance>(null);
  const [turnstileRequireInteractive, setTurnstileRequireInteractive] =
    useState(false);
  const dispatch = useDispatch();
  const [canLogin, setCanLogin] = useState(false);
  const [bLogo, setBLogo] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [welcomeVoucher, setWelcomeVoucher] = useState({
    brandColor: "",
    brandPattern: "",
    backgroundImageId: "",
    imageId: "",
    programName: "",
    itemName: "",
    valueDescription: "",
  });

  const onLogout = useCallback(async () => {
    await logout();
    dispatch(authSlice.actions.logout());
  }, [dispatch]);

  const redirect = useMemo(
    () => new URLSearchParams(search).get("redirect") || undefined,
    [search],
  );
  const allowedCountryCodes = [
    "al",
    "dz",
    "ad",
    "ao",
    "ag",
    "ar",
    "am",
    "aw",
    "au",
    "at",
    "az",
    "bs",
    "bh",
    "bd",
    "bb",
    "be",
    "bz",
    "bj",
    "bt",
    "bo",
    "ba",
    "bw",
    "br",
    "io",
    "bn",
    "bg",
    "bf",
    "bi",
    "kh",
    "cm",
    "ca",
    "cv",
    "bq",
    "td",
    "cl",
    "co",
    "km",
    "cg",
    "cr",
    "ci",
    "hr",
    "cw",
    "cy",
    "cz",
    "dk",
    "dj",
    "dm",
    "do",
    "ec",
    "eg",
    "sv",
    "gq",
    "er",
    "ee",
    "fj",
    "fi",
    "fr",
    "gf",
    "pf",
    "ga",
    "gm",
    "ge",
    "de",
    "gh",
    "gr",
    "gd",
    "gp",
    "gu",
    "gt",
    "gn",
    "gw",
    "gy",
    "ht",
    "hn",
    "hk",
    "hu",
    "is",
    "in",
    "id",
    "ie",
    "il",
    "it",
    "jm",
    "jp",
    "jo",
    "kz",
    "ke",
    "ki",
    "xk",
    "kw",
    "kg",
    "la",
    "lv",
    "ls",
    "lr",
    "li",
    "lt",
    "lu",
    "mo",
    "mk",
    "mg",
    "mw",
    "my",
    "mv",
    "mt",
    "mh",
    "mq",
    "mr",
    "mu",
    "mx",
    "fm",
    "md",
    "mc",
    "mn",
    "me",
    "ma",
    "mz",
    "mm",
    "na",
    "nr",
    "np",
    "nl",
    "nc",
    "nz",
    "ne",
    "ng",
    "no",
    "om",
    "pk",
    "pw",
    "ps",
    "pa",
    "pg",
    "py",
    "pe",
    "ph",
    "pl",
    "pt",
    "pr",
    "qa",
    "re",
    "ro",
    "rw",
    "kn",
    "lc",
    "vc",
    "ws",
    "sm",
    "st",
    "sa",
    "sn",
    "rs",
    "sc",
    "sl",
    "sg",
    "sk",
    "si",
    "sb",
    "za",
    "kr",
    "es",
    "lk",
    "sr",
    "sz",
    "se",
    "ch",
    "tw",
    "tj",
    "tz",
    "th",
    "tl",
    "tg",
    "to",
    "tt",
    "tn",
    "tr",
    "tm",
    "tv",
    "ug",
    "ae",
    "gb",
    "us",
    "uy",
    "uz",
    "vu",
    "va",
    "vn",
    "zm",
    "zw",
  ];

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return allowedCountryCodes.includes(iso2);
  });
  useEffect(() => {
    console.log("useEffect triggered");

    if (redirect) {
      console.log("Redirect found:", redirect);
    }

    if (loggedInUser) {
      //console.log("Logged in user found:", loggedInUser);
    }

    const urlParams = new URLSearchParams(window.location.search);
    // const redirect = urlParams.get("redirect");

    if (redirect?.startsWith("/membership/")) {
      const pathParts = redirect.split("/").filter(Boolean);
      const membershipUuid = pathParts[1];
      const offerVoucherUuid = getOfferVoucherUuid(redirect);

      if (membershipUuid) {
        localStorage.setItem("MembershipUuid", membershipUuid);
        localStorage.setItem("ScanMembershipUuid", membershipUuid);
      }

      if (offerVoucherUuid) {
        localStorage.setItem("offerVoucherUuid", offerVoucherUuid);
      }

      // Handle locationID if present
      if (pathParts[2]?.startsWith("locationID=")) {
        const locationID = pathParts[2].split("=")[1];
        localStorage.setItem("locationID", locationID);
        localStorage.setItem("ScanLocationID", locationID);
      }
      // Handle referralID if present
      else if (pathParts[2]?.startsWith("referralID=")) {
        const formattedReferrer = pathParts[2].replace("referralID=", "");
        localStorage.setItem("referrer", formattedReferrer);
      }
    }

    const storedMembershipUuid = localStorage.getItem("MembershipUuid");
    const offerVoucherUuid = localStorage.getItem("offerVoucherUuid");

    if (storedMembershipUuid) {
      // If we have an offer voucher UUID, fetch its details first
      if (offerVoucherUuid) {
        GetVouchersByLocation("Hong Kong S.A.R.")
          .then((businesses) => {
            // Find the business and voucher that matches our UUIDs
            for (const business of businesses) {
              if (business.uuid === storedMembershipUuid) {
                const voucher = business.vouchers.find(
                  (v) => v.code === offerVoucherUuid,
                );
                if (voucher) {
                  console.log("Voucher found:", voucher.backgroundImageId);
                  // Override welcome voucher with the offer voucher
                  setWelcomeVoucher({
                    brandColor: business.brandColor,
                    brandPattern: "",
                    backgroundImageId: voucher.backgroundImageId,
                    imageId: voucher.imageId,
                    programName: business.name,
                    itemName: voucher.campaignName,
                    valueDescription: voucher.valueDescription,
                  });
                  // Set the brand logo
                  setBLogo(
                    `${process.env.REACT_APP_CDN_URL}${business.imageURL}.jpg`,
                  );
                  console.log("B logo set:", bLogo);
                  break;
                }
              }
            }
          })
          .catch((error) =>
            console.error("Error fetching offer voucher:", error),
          );
      } else {
        // No offer voucher, use existing welcome voucher logic
        if (!bLogo) {
          GetProgramLogo(storedMembershipUuid).then(
            async (image: ProgramInfo) => {
              setBLogo(await image.logoUrl);
              if (image.programData) {
                setWelcomeVoucher(await image.programData);
              }
              return image;
            },
          );
        }
      }
    }

    localStorage.setItem("programName", welcomeVoucher.programName);
    //console.log(welcomeVoucher);
    if (!loggedInUser) {
      console.log("No logged in user found");
      return;
    }

    const fetchMemberships = async () => {
      try {
        const membership = await GetMemberships(loggedInUser.uuid);
        console.log("All memberships:", membership);
        // setCanLogin(true);
        LoginFunction();
      } catch (error) {
        console.error("Failed to login");
        dispatch(authSlice.actions.logout());
      }
    };

    fetchMemberships();
  }, [redirect, loggedInUser, dispatch, bLogo]);

  const LoginFunction = () => {
    //console.log("useEffect triggered");
    //console.log("loggedInUser?.uuid", loggedInUser?.uuid);

    if (!canLogin && !loggedInUser?.uuid) {
      return;
    }
    setIsLoading(true);

    //console.log("canLogin", canLogin);
    console.log("Login Started");
    const membershipUuid = localStorage.getItem("MembershipUuid");

    if (membershipUuid) {
      //console.log("Membership UUID found:", membershipUuid);

      if (!bLogo) {
        GetProgramLogo(membershipUuid).then((image: ProgramInfo) => {
          //console.log("Program logo image:", image);
        });
      }
      loggedInUser &&
        getMembership(loggedInUser.uuid, membershipUuid)
          .then((membership) => {
            //console.log("Membership details:", membership);

            if (membership) {
              //console.log("Setting membership:", membership);
              setMembership(membership);

              const newProgram = {
                id: membership.id,
                uuid: membership.uuid,
                name: membership.name,
                description: membership.description,
                isSelected: true,
                brandLogo: membership.imageURL,
                brandColor: membership.brandColor,
                placesDetail: membership.placesDetail,
                brandPattern: membership.brandPattern,
              };

              dispatch(programSlice.actions.addProgram(newProgram));
              localStorage.setItem("lastLogin", new Date().toISOString());
              localStorage.removeItem("MembershipUuid");
              localStorage.removeItem("offerVoucherUuid");
              navigate(`/home`);
            } else {
              //console.log("Joining membership:", membershipUuid);

              joinMembership({
                membershipUuid,
                shopperUuid: loggedInUser.uuid,
                referrerShopperUuid: localStorage.getItem("referrer") || "",
                locationId: localStorage.getItem("locationID")
                  ? Number(localStorage.getItem("locationID"))
                  : undefined,
              })
                .then(() => {
                  console.log("Joined membership successfully");

                  localStorage.setItem("newMembership", "true");
                  getMembership(loggedInUser.uuid, membershipUuid)
                    .then((newMembership) => {
                      //console.log("New membership details:", newMembership);

                      if (newMembership) {
                        setMembership(newMembership);

                        const newProgram = {
                          id: newMembership.id,
                          uuid: newMembership.uuid,
                          name: newMembership.name,
                          description: newMembership.description,
                          isSelected: true,
                          brandLogo: newMembership.imageURL,
                          brandColor: newMembership.brandColor,
                          placesDetail: newMembership.placesDetail,
                          brandPattern: newMembership.brandPattern,
                        };

                        dispatch(programSlice.actions.addProgram(newProgram));
                      }
                    })
                    .catch((error) => {
                      console.error("Error getting new membership:", error);
                      openNotification(
                        "error",
                        "Failed to get new membership details",
                        null,
                      );
                    });
                })
                .catch((error) => {
                  console.error("Error joining membership:", error);
                  openNotification(
                    "error",
                    "Failed to join membership for the program",
                    null,
                  );
                  navigate(`/`);
                });
            }
          })
          .catch((error) => {
            console.error("Error getting membership:", error);
            openNotification("error", "Failed to get membership", null);
          })
          .finally(() => {
            setIsLoading(false);
          });
    } else {
      console.log("No membership UUID found");

      loggedInUser &&
        GetMemberships(loggedInUser.uuid)
          .then((membership) => {
            //console.log("All memberships:", membership);

            if (membership && membership.length > 0) {
              navigate(`/meed`);
            }
          })
          .catch(() => {
            console.error("Failed to login");
            openNotification("error", "Failed to Login", null);
            onLogout();
            console.log("Logout");
          })
          .finally(() => {
            setIsLoading(false);
          });
    }
    localStorage.removeItem("referrer");
  };

  const handlePhoneLogin = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      console.log("handlePhoneLogin triggered");
      setIsLoading(true);

      try {
        const response = await getPhoneOtp({
          phoneNumber,
          deviceID: getDeviceUuid(),
        });

        const checkPhoneResponse = await CheckPhone(phoneNumber);
        if (checkPhoneResponse.result == true) {
          if (response.result) {
            dispatch(authSlice.actions.setphoneNumberForLogin(phoneNumber));
            localStorage.removeItem("offerVoucherUuid");
            openNotification(
              "success",
              `An OTP has been delivered to the phone number ${phoneNumber}`,
              null,
            );
            navigate({
              pathname: "/verify",
              search,
            });
          }
        } else {
          localStorage.setItem("phoneNumber", phoneNumber);
          localStorage.removeItem("offerVoucherUuid");
          navigate({
            pathname: "/accountCreation",
            search,
          });
        }
      } catch (err) {
        console.error("Error during phone login:", err);
        openNotification("error", `Unable to process phone login`, null);
      } finally {
        setIsLoading(false);
      }
    },
    [phoneNumber, dispatch, navigate, search],
  );
  const storedMembershipUuid = localStorage.getItem("MembershipUuid");
  return (
    <div style={{ background: "white" }}>
      {isLoading && <LoadingSpinner />}
      <div className="auth">
        <div
          className={`auth__head ${welcomeVoucher.brandPattern}`}
          style={
            {
              backgroundColor: welcomeVoucher.brandColor,
              // "--color": welcomeVoucher.brandColor,
            } as React.CSSProperties
          }
        >
          <Container
            className="auth_business_head-wrap"
            variant="smartphone-column"
          >
            <img
              className="wellcome__logo"
              src={bLogo || LogoImagePath} // Use the state variable here
              width="150"
              height="150"
              alt=""
            />
          </Container>
          {storedMembershipUuid && welcomeVoucher.itemName && (
            <Container className="wellcome__wrap" variant="smartphone-column">
              <Deal
                background={welcomeVoucher.brandColor}
                brandLogo={bLogo}
                campaignPicture={`${process.env.REACT_APP_CDN_URL}${welcomeVoucher.imageId}.jpg`}
                brandName={welcomeVoucher.programName}
                offerTitle={welcomeVoucher.itemName}
                offerValue={welcomeVoucher.valueDescription}
                // duration={`${calculateDuration(
                //   new Date(),
                //   new Date(content.startDate),
                //   new Date(content.expiryDate),
                // )}`}
              />
              {/* <div className="wellcome__voucher-title">
              10% off voucher for Honbo
            </div>
            <div className="wellcome__voucher-description">
              This is a high level description
            </div> */}
            </Container>
          )}
        </div>
        <Container className="auth__wrap">
          <form
            className="auth__form"
            onSubmit={(e) => {
              e.preventDefault();
              handlePhoneLogin(e);
            }}
          >
            <fieldset className="auth__fieldset" disabled={isLoading}>
              <p
                className="wellcome__reminder"
                style={{ fontSize: 16, marginTop: 5 }}
              >
                {storedMembershipUuid && welcomeVoucher.programName && (
                  <>
                    To receive this voucher, sign up today
                    <br />
                  </>
                )}
              </p>
              <p className="wellcome__reminder" style={{ marginTop: 5 }}>
                Please enter your phone number.
                <br />
                Skip the zero if it starts with a zero.
              </p>
              <div className="wellcome__panel">
                <div className="auth__field">
                  <Label className="label">Mobile Number</Label>
                  <PhoneInput
                    defaultCountry="hk"
                    value={phoneNumber}
                    onChange={(value) => {
                      setPhoneNumber(value);
                    }}
                    countries={countries}
                    className="phone-input"
                  />
                </div>
              </div>

              <div className="wellcome__button">
                <AppButton type="submit" uniqueModifier="submit" fullWidth>
                  Log In
                </AppButton>
              </div>
            </fieldset>
          </form>
          <p className="wellcome__reminder">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  "https://www.meedloyalty.com/privacy-policy";
              }}
            >
              Privacy Policy
            </a>{" "}
            applies.
          </p>
        </Container>
      </div>
      <p
        style={{
          fontSize: 10,
          color: "black",
          textAlign: "center",
          paddingRight: 5,
        }}
      >
        meed - the loyalty people want
      </p>
      <p
        style={{
          fontSize: 10,
          color: "lightgray",
          textAlign: "right",
          paddingRight: 5,
        }}
      >
        v0.9.0
      </p>
    </div>
  );
}

export default WellcomeBusiness;
