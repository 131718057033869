import React, { useState, useEffect } from "react";
import { Modal, Button, Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import "./meed-offers.css";
import "../../shared/imports/_variables.css";
import "../../shared/imports/hardcoded-components/timer.css";
import "../../shared/imports/hardcoded-components/location.css";
import "../../shared/imports/hardcoded-components/deal.css";
import Container from "../../Components/container/container";
import VoucherCard from "../../Components/voucher-card/voucher-card";
import { motion, AnimatePresence } from "framer-motion";
import { GetVouchersByLocation } from "../../services/api/getVouchers";
import { ReactComponent as ClockSymbol } from "../../assets/symbols/clock.svg";
import { ReactComponent as MeedLogo } from "./logo/meed logo-side orange.svg";
import BrandLogoPlaceholder from "../../assets/placeholders/placeHolder.png";
import { GetImage } from "../../services/api/getImage";

const { Panel } = Collapse;

interface Voucher {
  id: number;
  code: string;
  campaignName: string;
  description: string;
  valueDescription: string;
  startDate: string;
  expiryDate: string;
  imageId: string;
  backgroundImageId: string;
  termsNConditions: string;
}

interface Business {
  id: number;
  uuid: string;
  name: string;
  description: string;
  imageURL: string;
  brandColor: string;
  vouchers: Voucher[];
}

interface SelectedVoucherData {
  business: Business;
  voucher: Voucher;
}

function MeedOffers() {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [selectedVoucher, setSelectedVoucher] =
    useState<SelectedVoucherData | null>(null);
  const [brandLogoSrc, setBrandLogoSrc] =
    useState<string>(BrandLogoPlaceholder);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const fetchVouchers = async () => {
      try {
        const data = await GetVouchersByLocation("Hong Kong S.A.R.");
        console.log(data);
        setBusinesses(data);
      } catch (error) {
        console.error("Error fetching vouchers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVouchers();
  }, []);

  const calculateRemainingDays = (expiryDate: string) => {
    const today = new Date().getTime();
    const expiry = new Date(expiryDate).getTime();
    return Math.ceil((expiry - today) / (1000 * 60 * 60 * 24));
  };

  const handleVoucherClick = (business: Business, voucher: Voucher) => {
    setSelectedVoucher({ business, voucher });
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedVoucher(null);
  };

  const handleJoinClick = () => {
    if (isMobile) {
      navigate("/");
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
  };

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24,
      },
    },
  };

  useEffect(() => {
    if (selectedVoucher?.business.imageURL) {
      GetImage(selectedVoucher.business.imageURL)
        .then((image) => setBrandLogoSrc(image || BrandLogoPlaceholder))
        .catch(() => setBrandLogoSrc(BrandLogoPlaceholder));
    }
  }, [selectedVoucher]);

  return (
    <>
      <header className="meed-header">
        <MeedLogo className="meed-logo" />
        <nav className="meed-nav">
          <a href="https://www.meedloyalty.com/">HOME</a>
          <a href="https://www.meedloyalty.com/faq">FAQ</a>
          <a href="https://www.meedloyalty.com/about-meed">ABOUT</a>
          <a href="https://www.meedloyalty.com/blog">BLOG</a>
        </nav>
      </header>

      <div className="meed-offers-wrapper">
        <Container className="meed-offers-container">
          <div className="meed-offers">
            <h1 className="meed-offers__title">
              Offers Available Now on meed in Hong Kong
            </h1>
            <p className="meed-offers__subtitle">
              Click any offer and scan the QR code to join
            </p>

            <AnimatePresence mode="wait" initial={false}>
              <motion.div
                className="meed-offers__list"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                layout
                style={{
                  willChange: "transform, opacity",
                  backfaceVisibility: "hidden",
                }}
              >
                {businesses.map((business) =>
                  business.vouchers.map((voucher) => (
                    <motion.div
                      key={`${business.id}-${voucher.id}`}
                      variants={cardVariants}
                      layout
                      style={{
                        willChange: "transform",
                        backfaceVisibility: "hidden",
                      }}
                    >
                      <VoucherCard
                        brandName={business.name}
                        startDate={voucher.startDate}
                        expriyDate={voucher.expiryDate}
                        discount={voucher.valueDescription}
                        product={voucher.campaignName}
                        location="Hong Kong"
                        backgroundColor={business.brandColor || "#1a237e"}
                        voucherImage={voucher.imageId}
                        brandImage={business.imageURL}
                        textColor="#FFFFFF"
                        unlockVisitThreshold={0}
                        recordedVisits={0}
                        code={voucher.code}
                        programUuid={business.uuid}
                        onClick={() => handleVoucherClick(business, voucher)}
                      />
                    </motion.div>
                  )),
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </Container>

        <Modal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          width={800}
          className="voucher-detail-modal"
        >
          {selectedVoucher && (
            <div className="voucher-detail">
              <div className="voucher-detail-header">
                <img
                  src={brandLogoSrc}
                  alt={selectedVoucher.business.name}
                  className="voucher-detail-brand-logo"
                />
                <div className="voucher-detail-title">
                  <h2>{selectedVoucher.business.name}</h2>
                </div>
              </div>

              <div className="voucher-detail-content">
                <p className="voucher-detail-campaginName">
                  {selectedVoucher.voucher.campaignName}
                </p>
                <p className="voucher-detail-value">
                  {selectedVoucher.voucher.valueDescription}
                </p>
                <p className="voucher-detail-description">
                  {selectedVoucher.voucher.description}
                </p>

                <div className="voucher-detail-dates">
                  <div className="date-item">
                    <ClockSymbol className="timer__icon" />
                    <p>
                      {calculateRemainingDays(
                        selectedVoucher.voucher.expiryDate,
                      )}{" "}
                      days remaining
                    </p>
                  </div>
                </div>

                <Collapse ghost className="voucher-detail-collapse">
                  <Panel header="Terms & Conditions" key="1">
                    <p>
                      {selectedVoucher.voucher.termsNConditions
                        .split(/(?=\d+\.)/)
                        .map((item, index) => (
                          <React.Fragment key={index}>
                            {item.trim()}
                            <br />
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                  </Panel>
                </Collapse>
              </div>

              {isMobile ? (
                <Button
                  type="primary"
                  block
                  size="large"
                  onClick={handleJoinClick}
                  className="join-button"
                >
                  Join and Claim
                </Button>
              ) : (
                <div className="qr-code-container">
                  <QRCode
                    value={`${window.location.origin}/membership/${selectedVoucher.business.uuid}/offerVoucherUuid=${selectedVoucher.voucher.code}`}
                    size={200}
                    level="H"
                  />
                  <p>Scan to join meed</p>
                </div>
              )}
            </div>
          )}
        </Modal>
      </div>

      <footer className="meed-footer">
        <div className="meed-footer-content">
          <MeedLogo className="meed-footer-logo" />
          <nav className="meed-footer-nav">
            <a href="https://www.meedloyalty.com/privacy-policy">
              Privacy Policy
            </a>
            <a href="https://www.meedloyalty.com/terms-of-use">Terms of Use</a>
            <a href="https://www.meedloyalty.com/software-as-a-service-terms-of-service">
              Software-as-a-Service Terms of Service
            </a>
            <a href="https://www.meedloyalty.com/membership-terms-and-conditions">
              Membership Terms and Conditions
            </a>
            <a href="https://www.meedloyalty.com/data-deletion">
              Account Deletion
            </a>
            <a href="https://getmeed.notion.site/">Help</a>
          </nav>
        </div>
      </footer>
    </>
  );
}

export default MeedOffers;
