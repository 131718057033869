import { AxiosResponse, isAxiosError } from "axios";
import axiosInstance from "./axiosIntercept";

type MilestoneCampaignThresholdReward = {
  bankVoucherCampaignId: number;
  id: number;
  milestoneStampcardVoucherCampaignId: number;
  stampingDigitalVoucherIssueThreshold: number;
};
export const getMilestoneCampaignThresholdRewards = async (
  milestoneCampaignId: number | string,
): Promise<MilestoneCampaignThresholdReward[]> => {
  try {
    const response = await axiosInstance.post<
      any,
      AxiosResponse<MilestoneCampaignThresholdReward[]>,
      { milestoneVoucherId: string | number }
    >(
      "v1/voucher/milestone/threshold/rewards",
      {
        milestoneVoucherId: milestoneCampaignId,
      },
      {
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "content-type": "application/json",
        },
      },
    );
    //console.log(response.data);
    return response.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};

interface MilestoneCampaignResponse {
  visitCount: number;
  milestoneCard: Array<{
    id: number;
    // ... other milestone card properties
  }>;
  rewardDigitalVouchers: Array<{
    id: number;
    code: string;
    isValid: boolean;
    voucherCampaignId: number;
    Voucher: {
      id: number;
      code: string;
      value: number;
      expiryDate: string;
      startDate: string;
      campaignName: string;
      imageId: string;
      requirements: { requirements: string[] };
      termsNConditions: string;
      description: string;
      backgroundImageId: string;
      location: string;
      valueDescription: string;
      eventAddress: string;
      eventCode: string | null;
      specialAddress: string;
      backgroundColor: string;
      termsNConditionsURL: string;
      memberSelfStamping: boolean;
      memberSelfStampingWaitHours: number;
      memberReceiptScanning: boolean;
      referralRewardVoucher: boolean;
    };
  }>;
}

export const getMilestoneCampaign = async (
  programUuid: number | string,
  shopperUuid: number | string,
): Promise<MilestoneCampaignResponse | undefined> => {
  try {
    const { data } = await axiosInstance.post<MilestoneCampaignResponse>(
      "/v1/shopper/milestone/stamps",
      {
        programUuid,
        shopperUuid,
      },
      {
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "content-type": "application/json",
        },
      },
    );

    if (typeof data === "string") {
      return undefined;
    }

    return data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};

export const getVoucherBank = async (programId: number): Promise<any> => {
  try {
    const response = await axiosInstance.get<any>(`/v1/voucher/bank`, {
      params: {
        programId,
      },
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    //console.log("Voucher Bank response", response);
    return response;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};
