import React, { useEffect, useState } from "react";
import "../../shared/imports/_variables.css";
import "./program.css";
import PWAHeader from "../../Components/pwa-header-meedpage/pwa-header-meed";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import Card from "../../Components/card/card";
import Wysiwyg from "../../Components/wysiwyg/wysiwyg";
import getDiscoveryProgram from "../../hooks/useDiscoveryProgram";
import ProgramCard from "../../Components/program-card/program-card";
import { GetImage } from "../../services/api/getImage";
import BrandLogoPlaceholder from "../../assets/placeholders/placeHolder.png";
import { animate } from "motion";
import { ReactComponent as BackIcon } from "../../assets/symbols/back.svg";
import { Button } from "antd";
import { ReactComponent as Location } from "../../assets/symbols/geo.svg";
import { joinMembership } from "../../services/api/joinMembership";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import {
  GetMembershipsResponse,
  GetMemberships,
} from "../../services/api/getMemberships";
import openNotification from "../../Components/Antd/Notification/Notification";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { programSlice } from "../../store/slices/data";

function Program() {
  const discoveryProgram = getDiscoveryProgram();
  const loggedInUser = useLoggedInUser();
  const [imageSrc, setImageSrc] = useState(BrandLogoPlaceholder);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [program, setProgram] = useState({
    id: 0,
    uuid: "1",
    name: "2",
    description: "3",
    isSelected: false,
    brandLogo: "4",
    brandColor: "5",
    placesDetail: "6",
    brandPattern: "7",
  });
  const [membership, setMembership] = useState<GetMembershipsResponse[0]>();

  useEffect(() => {
    animate(
      ".discover__section",
      {
        // Keyframes (array or single value).
        opacity: [0, 1],
      },
      {
        duration: 0.8,
      },
    );
  });

  useEffect(() => {
    const downloadImage = async () => {
      try {
        const downloadedImage = await GetImage(
          discoveryProgram?.programLogoImageHash ?? "",
        );
        setImageSrc(downloadedImage);
      } catch (error) {
        console.error("Failed to download image", error);
      }
    };

    downloadImage();
  }, [discoveryProgram]);

  const handleBackButtonClick = () => {
    window.history.back();
  };

  const getMembership = async (
    loggedInUserUuid: string,
    programUuid: string,
  ) => {
    const memberships = await GetMemberships(loggedInUserUuid);

    return memberships.find((x) => x.uuid === programUuid);
  };

  const handleJoinMembership = async () => {
    getMembership(
      loggedInUser?.uuid || "",
      discoveryProgram?.programUuid || "",
    ).then(async (membership) => {
      if (membership) {
        setMembership(membership);
        const newProgram = {
          id: membership?.id || 0,
          uuid: membership?.uuid || "",
          name: membership?.name || "",
          description: membership?.description || "",
          isSelected: true,
          brandLogo: membership?.imageURL || "",
          brandColor: membership?.brandColor || "",
          placesDetail: membership?.placesDetail || "",
          brandPattern: membership?.brandPattern || "",
        };
        setProgram(newProgram);
        dispatch(programSlice.actions.addProgram(newProgram));
        navigate(`/home`);
        setIsLoading(false);
        return;
      }

      try {
        await joinMembership({
          membershipUuid: discoveryProgram?.programUuid || "",
          shopperUuid: loggedInUser?.uuid || "",
        });
        const newMembership = await getMembership(
          loggedInUser?.uuid || "",
          discoveryProgram?.programUuid || "",
        );
        localStorage.setItem("newMembership", "true");
        console.log(newMembership);
        setMembership(newMembership);
        setProgram({
          id: newMembership?.id || 0,
          uuid: newMembership?.uuid || "",
          name: newMembership?.name || "",
          description: newMembership?.description || "",
          isSelected: true,
          brandLogo: newMembership?.imageURL || "",
          brandColor: newMembership?.brandColor || "",
          placesDetail: newMembership?.placesDetail || "",
          brandPattern: newMembership?.brandPattern || "",
        });
      } catch (e) {
        openNotification(
          "error",
          `Failed to join membership for the program`,
          null,
        );
      }
    });
  };
  useEffect(() => {
    if (program.uuid !== "1") {
      dispatch(programSlice.actions.addProgram(program));
      localStorage.removeItem("MembershipUuid");
      navigate(`/home`);
    }
  }, [program, navigate]);
  // console.log(selectedProgram);
  return (
    <div className="pwa-page">
      <BackIcon className="discovery-back" onClick={handleBackButtonClick} />

      <PWAHeader />

      <Container className="program">
        <ProgramCard
          brandImage={imageSrc}
          brandName={discoveryProgram?.programName || ""}
          catagory={discoveryProgram?.productTypeDescription || ""}
        />
        {discoveryProgram?.programJoiningAllowed && (
          <Button
            className="discovery-program__button"
            type="primary"
            onClick={handleJoinMembership}
          >
            Join now
          </Button>
        )}

        <h1 className="discover__heading">About</h1>
        <div className="discover__body">
          <Card className="discover__section">
            <Wysiwyg>
              <p
                style={{ whiteSpace: "pre-line", textAlign: "left" }}
                className="discovery__description"
              >
                {discoveryProgram?.programDescription ?? ""}
              </p>
            </Wysiwyg>
          </Card>

          <h1 className="discover__heading">Location</h1>
          <Card className="discover__section">
            <Wysiwyg>
              <p
                style={{ whiteSpace: "pre-line", textAlign: "left" }}
                className="discovery__location"
              >
                {discoveryProgram?.programName ?? ""}
              </p>
              <div
                className="location location--large discovery"
                style={{ padding: "10px 15px 25px 15px" }}
              >
                <Location className="location__icon" />
                {discoveryProgram?.programLocation ?? ""}
              </div>
            </Wysiwyg>
          </Card>
          {/* <Card className="about__section">
            <div className="about__social-media">
              <Media url="http://instagram.com" icon={MediaWebSymbol} />
              <Media url="http://instagram.com" icon={MediaInstagramSymbol} />
              <Media url="http://instagram.com" icon={MediaFacebookSymbol} />
              </div>
            </Card> */}
        </div>
      </Container>
      <PWANav />
    </div>
  );
}

export default Program;
